.navbar-vertical.navbar-expand-xs .navbar-nav .nav-link:not([data-toggle="collapse"]) {
    padding: 0.4rem 1.5rem !important
}

.navbar-vertical .navbar-nav:not(.active),
.navbar-vertical .nav-link:not(.active)>i {
    color: #fa5252 !important;

    &.ni-fat-add {
        color: #ced4da !important;
    }
}

.navbar-vertical .nav-link.active {
    &[data-toggle="collapse"] {
        font-weight: bold !important;
        background: transparent !important;

        i {
            color: #fa5252 !important
        }
    }

    .badge {
        &.badge-primary {
            background-color: #fff !important;
            color: #fa5252 !important;
        }
    }
}

.navbar-vertical .nav-link.active:not([data-toggle="collapse"]) {
    background: rgb(250, 82, 82) !important;
    background: linear-gradient(90deg, rgba(250, 84, 84, 1) 0%, rgba(250, 84, 84, 0.3981967787114846) 100%) !important;
    color: white !important;
    border-radius: 0 !important;
    margin: 0rem 0 !important;
    padding: 0 0rem !important;

    i {
        color: white !important;
    }

}

.navbar-vertical .navbar-nav .nav-link.active[data-toggle='collapse'][aria-expanded='true']:after {
    color: white !important
}

.navbar-vertical.navbar-expand-xs .navbar-nav .nav .nav-link.active span.sidenav-mini-icon {
    color: white !important
}


.sidebar-search-input .input-group {
    .input-group-text {
        padding-right: 0;
        border-right: none;
    }

    input {
        border-left: none;

        &:focus {
            border-color: #dee2e6;
        }

        &:focus-visible {
            border-color: #dee2e6;
        }
    }
}

.h-sidebar-content {
    height: calc(100% - 45px) !important;
}

.badge {
    &.badge-primary {
        background-color: #fa5252 !important;
        color: #fff !important;
    }
}

.box-shadow-bottom {
    box-shadow: 0 5px 10px -5px #dee2e6;
}

.version-platform {
    font-size: 0.7rem;
    line-height: 0.7rem;
    font-weight: 500;
    color: #afb0b0 !important;
    padding: 0.4rem 0.75rem;
    width: max-content;
    margin: auto;
    border-radius: 50px;
    text-align: center !important;
}
.bold-item{
    font-weight: bold;
}
.add-icon {
    line-height: 0.9375rem !important;
    border-radius: 50% !important;
    min-width: unset !important;
    width: 1.5rem !important;
    height: 1.5rem !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    top: unset !important;
}
  
/* Dropdown Indicator */
.status-icon {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
  }
  
  .status-icon.open_for_project {
    background-color: #28a745; /* Green */
  }
  
  .status-icon.open_for_part_time {
    background-color: #ffc107; /* Yellow */
  }
  
  .status-icon.not_available {
    background-color: #dc3545; /* Red */
  }
  
  .status-header {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .status-toggle {
    display: flex;
    align-items: center;
  }
 .status-dropdown{
    transition: none !important;

 }
  
  .status-toggle .status-icon {
    margin-right: 10px;
  }
  .status-collapsed{
    display: none;
    text-align: center;
    margin: 0.5rem;
  }
  .user-status{
    border: 1px solid rgb(180, 180, 180);
    border-radius: 10px;
    padding: 5px;
  }
  .g-sidenav-hidden:not(.g-sidenav-show) .sidenav {
    .logout-item span {
        display: none
    }

    .sidebar-logo {
        display: none !important;
    }

    .sidebar-toggle-icon {
        margin: auto !important;
    }

    .version-platform {
        display: none !important;
    }

    .sidebar-user-info {
        justify-content: center;
        width: 40px;
        height: 40px;
        margin: auto !important;

    .user-information {
            display: none !important;
        }

        .status-collapsed{
            display: block;
          }
    }
    .user-status {
        display: none !important;
    }
    .status-collapsed{
        display: block;
      }
}

