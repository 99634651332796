.jobs-container {
    .job-detail-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .job-breadcrumb {
        top: 0 !important;
        left: 0 !important;
        padding-left: 0 !important;

        .breadcrumb {
            margin-bottom: 0;
            padding: 0;
        }
    }

    .job-details .custom-control-input:checked~.custom-control-label::before {
        background-color: #63b1bd !important;
    }

    .published-job {
        font-size: 13px;
    }
}
.notes-container{
    display: flex;
    flex-direction: column;
    gap:15px;
.note-item{
    margin: 2px;
   
    .note-content{
        margin: 0;
        font-weight: 400;
        font-size: 14px;
        color: rgb(69, 68, 68);
    }

}
}
.note-content{
    color: black
}