.drag-drop-upload {
    border: 2px dashed #ccc;
    border-radius: 5px;
    padding:20px;
    text-align: center;
    transition: background-color 0.3s ease;
    height: 8.2rem;
}

.drag-drop-upload.drag-over {
    background-color: #e8f7ff;
    border-color: #007bff;
}

.upload-label {
    color: #555;
}
    
    .fa-plus {
        background-color: #63B1BD;
        border-radius: 50%;
        color: #fff;
        width: 20px;
        height: 20px;
        justify-content: center;
        font-size: 12px;
        padding: 5px;
    }
    

.file-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.file-preview {
    margin-right: 5px;
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 5px;
}

.image-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.file-icon {
    margin-right: 10px;
    font-size: 24px;
}

a {
    text-decoration: none;
    color: red;
    margin-left: 5px;
}