.mission-applicant-page {
    .table-responsive {
        .custom-control-input {
            left: 0;
            top: 0;
        }

        .custom-control-label::before {
            left: 0;
            top: 0;
        }

        .custom-control-label::after {
            left: 0;
            top: 0;
        }

        td {
            .progress {
                height: 15px;

                .progress-bar {
                    background-color: #63b1bd;
                }
            }
        }
    }

    @media screen and (max-width: 900px) {
        .mission-applicant-content {
            flex-direction: column;

            .col-3 {
                max-width: 100%;
            }

            .col-9 {
                max-width: 100%;
            }
        }
    }
}