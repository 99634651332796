.container-home{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex-grow: 1;
  margin: 0;
}

.onboard-card {
  border: 1px solid black;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-title {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.card-text {
  font-size: 1rem;
  color: #333;
}

.button {
  font-size: 1.1rem;
  font-weight: 600;
  background-color: transparent;
  width: 100%;
  height: 3rem;
  color: #fa5252;
  display: block;
  margin: 0 auto;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 0 0 10px 10px;
}

.button:hover {
  background-color: #fa5252;
  color: white;
}

.status-card {
  height: 80%;
}

.matching-card {
  height: 14.1rem;
  font-size: 14px;
  display: flex;
  flex-direction: column; 

  .job-details {
    display: flex;
    gap: 0.2rem;
    padding: 0.2rem;
    .job-details-item {
      display: flex;
      align-items: center;
      gap: 0.2rem;
      font-size: 12px;
      flex-grow: 1;
    }
  }
}



.extra-card {
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: row;
  height: 8rem;
  align-items: center;
  justify-content: space-evenly;
  color: rgb(29, 28, 28);
  
}

.extra-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.extra-text p {
  font-size: 1.15rem;
  font-weight: 300;
  text-align: left;
  line-height: 1.7rem;
}

.extra-image {
  margin-bottom: 15px;
}

.extra-image img {
  width: 60px;
  height: 60px;
}
.cards-container{
  display: flex;
  flex-wrap: wrap;
}

.add-shortcut{
  background-color: #63b1bd;
  color:white;
  display: flex;
  gap:5px;
  height: 2rem;
  align-items: center;
  justify-content: space-evenly;
  border: 0;
  border-radius: 10px;
  padding: 5px;
  font-weight: 500;
  font-size:13px;
  margin-left: -2px;
  .fa-plus{
    background-color: white;
    border-radius: 50%;
    color: #63b1bd;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:8px;
  }
}
.subscription-name{
  font-size: 17px;
  font-weight:600;
}
@media (max-width: 768px) {
  .onboard-card,
  .status-card,
  .matching-card,
  .extra-card {
    width: 100%;
    max-width: none;
    height: auto;
  }
  .extra-text p {
    font-size: 0.9rem;
    line-height: 1.2rem;
  }

  .extra-image img {
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 576px) {
  .container-home {
    padding: 0.5rem;
  }

  .extra-text p {
    font-size: 0.8rem;
  }

  .extra-image img {
    width: 35px;
    height: 35px;
  }
}
.steps-container{
  width:100%;
}