.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.extra-height {
  height: 20rem;
}

.bg-gray-500 {
  background-color: #e0e0e0;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.color-gray-200 {
  color: #d9d7d7 !important;
}

.App-link {
  color: #61dafb;
}

.avatar-sm {
  width: 36px !important;
}

.avatar img {
  height: 100%;
}

.card-height-tasks {
  height: 90%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.card-profile-image img {
  width: 140px;
  height: 140px;
}

.flags .dropdown-item img,
.flags .dropdown-toggle img {
  width: 20px;
  height: 20px;
}

.loader-container {
  position: fixed;
  z-index: 1111;
  display: flex;
  /* justify-content: center; */
  width: 100vw;
  height: 100vh;
  background: #212121c4;
}

/* .inner-nav {
  background-color: #11cdef;
} */

.inner-nav a:hover {
  color: #172b4d !important;
}

.width-16 {
  width: 16rem;
  max-width: 100%;
}

.fs-2 {
  font-size: 1.5rem;
}

.fs-3 {
  font-size: 1.2rem;
}

.custom-height .table-responsive {
  height: 100%;
}

.onboarding-circle {
  background-color: #1aae6f;
  width: 3rem;
  height: 3rem;
  flex-shrink: 0;
  border-radius: 6rem;
}

.onboarding-line {
  width: 0.2rem;
  height: 100%;
  background-color: #e9e3e3;
}

.card-onboarding {
  min-width: 400px;
  flex-grow: 3;
}

.btn-upload {
  position: absolute;
  bottom: 10px;
  right: 5px;
  display: none;
  padding: 0.4rem 0.7rem;
}

.btn-upload i {
  font-size: 1.2rem;
}

.upload-input {
  display: none;
}

.custom-hover {
  transition: all 0.6s;
}

/* .custom-hover:hover {
  filter: brightness(0.7);
} */
.custom-hover:hover .btn-upload {
  display: block;
}

.custom-filter {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #0202028c;
  transition: filter 0.5s;
  transform: scale(0);
}

.custom-hover:hover .custom-filter {
  transform: scale(1);

  filter: blur(20px);
}

.card-profile-image img {
  transition: all 0.5s;
}

.card-profile-image:hover img {
  filter: brightness(0.5);
}

.icon-upload {
  position: absolute;
  transform: translate(-50%, -50%) scale(1);
  left: 50%;
  font-size: 1.8rem;
  color: #d7d7d7;
  cursor: pointer;
  display: none;
}

.card-profile-image:hover .icon-upload {
  display: block;
}

.icon-network {
  position: absolute;
  right: 15px;
  top: 11px;
}

.bg-transparent {
  background: transparent ;
  z-index: 9;
}

.parent {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(220px, 100%), 1fr));

  grid-template-rows: repeat(auto-fill, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 0px;
}

.profile-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(270px, 100%), 1fr));
  grid-template-rows: auto;
  grid-column-gap: 15px;
  grid-row-gap: 2px;
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(294px, 100%), 1fr));
  grid-template-rows: auto;
  grid-column-gap: 10px;
  grid-row-gap: 2px;
}

.max-content {
  height: 41rem;
  max-width: 500px;
  overflow: hidden;
}

body {
  max-width: 2900px;
  margin: auto;
}

html {
  scroll-behavior: smooth;
}

.color-gray-700,
.color-gray-700:hover {
  color: #525f7f !important;
}

.max-content-skills {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.max-content-text {
  display: -webkit-box;
  padding: 1rem 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.border-size-5 {
  border-width: 5px !important;
}

.w-max-content {
  width: max-content;
}

.border-dashed {
  border-style: dashed !important;
}

.icon-micro {
  font-size: 4rem;
  transform: rotate(-34deg);
}

.item-details {
  color: gray;
  transition: background 0.4s;
  cursor: pointer;
  background-image: linear-gradient(120deg, #f7fafc 0%, #f7fafc 50%, #ffff 50%);
  background-size: 250%;
}

.item-details:hover {
  background-position: 100%;
}

.item-selected {
  border-left: 3px solid #212529;
  background-position: 100%;

  color: #212529;
}

.main-content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.object-fit-cover {
  object-fit: cover;
}

.object-fit-scale-down {
  object-fit: scale-down;
}

@media (max-width: 860px) {
  .wrap {
    flex-wrap: wrap;
    justify-content: center;
    flex-basis: 1 !important;
    width: 95vw;
  }

  .card-onboarding {
    margin-right: 0 !important;
  }

  .wrap span.btn {
    margin: 0.2rem;
  }
}

@media (max-width: 600px) {
  .custom-navbar {
    display: none !important;
  }

  .custom-header {
    flex-wrap: wrap;
    justify-content: center !important;
  }

  .icon-upload {
    display: block;
  }

  .btn-upload {
    display: block;
  }

  .btn {
    font-size: 0.6rem !important;
    padding: 0.5rem 0.6rem !important;
  }

  .card-onboarding {
    min-width: 100%;
  }

  .icon-micro {
    font-size: 3rem;
  }
}

@media (max-width: 992px) {
  .c-order-5 {
    order: 5;
    padding-bottom: 2rem;
  }

  .width-7 {
    width: 7rem;
  }

  .no-m {
    margin-top: 1rem !important;
  }
}

/* Example Styles for React Tags*/

.container {
  margin: auto;
}

.ReactTags__tags {
  position: relative;
}

.ReactTags__clearAll {
  cursor: pointer;
  padding: 10px;
  margin: 10px;
  background: #f88d8d;
  color: #fff;
  border: none;
}

/* Styles for the input */
.ReactTags__tagInput {
  border-radius: 2px;
  display: inline-block;
}

.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  font-size: 12px;
  border: 1px solid #eee;
  min-width: 150px;
}

.ReactTags__editInput {
  border-radius: 1px;
}

.ReactTags__editTagInput {
  display: inline-flex;
}

.flex-w-50 {
  flex: 0 0 50%;
}

.delete-btn {
  position: absolute;
  top: -15%;
  right: -10%;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 100%;
  background-color: #fa5252;
  border: none;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-end {
  align-self: end;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #63bcfd;
  color: white;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  border-radius: 2px;
}

.ReactTags__selected span.ReactTags__tag:first-child {
  margin-left: 0px;
}

.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
.ReactTags__suggestions {
  position: absolute;
  top: 60px;
}

.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}

.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}

.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: white;
}

/* GENERAL */
.v-none {
  visibility: hidden !important;
}

.c-pointer {
  cursor: pointer !important;
}

.recruiter-card {
  cursor: pointer !important;
  background: #63b1bd;
  height: 80px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
}

.card-recruiter-detail-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
}

.card-recruiter-details {
  width: 100%;

  height: auto;
}

.recruiter-hover:hover {
  background-color: #e2e5eb47;
  cursor: pointer;
}

.user-card .user-image {
  height: 300px;
}

.height-83 {
  height: 83px;
}

.dz-message {
  z-index: 1 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.card-tags {
  padding: 2.5rem !important;
}

.text-white.active:hover {
  color: white !important;
}

.fs-1 {
  font-size: 0.9rem;
  font-weight: 500;
}

.hover_title:hover {
  background-color: #eeeeee;
  cursor: pointer;
}

.bigFontSize {
  font-size: 5rem;
}

.max-note-list-height {
  max-height: 400px;
  overflow: auto;
}

.max-note-list-height::-webkit-scrollbar {
  width: 7px;
}

.max-note-list-height::-webkit-scrollbar-track {
  background-color: rgb(31, 195, 236);
  border-radius: 6px;
}

.max-note-list-height::-webkit-scrollbar-thumb {
  background-color: #3182ce;
  border-radius: 6px;
  border: 3px solid #3182ce;
}

.select-icon option {
  font-size: 1rem;
  padding: 1rem;
}

.icon-groups {
  top: 60% !important;
}

.paginate-button {
  width: 3rem;
  height: 3rem;
  font-size: 1.1rem;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 0.5rem;
  background-color: white;
  color: rgb(209, 209, 209);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.paginate-button.active {
  background-color: #172b4d;
  color: white;
}

.gap-1 {
  gap: 0.5rem;
}

.gap-2 {
  gap: 1rem;
}

.gap-3 {
  gap: 1.5rem;
}

.paginate-button:disabled {
  background-color: rgb(182, 182, 182);
}

.min-height-30 {
  min-height: 30rem;
}

.min-height-20 {
  min-height: 28rem;
}

.PhoneInput {
  display: flex;
}

.height-30 {
  min-height: 38rem;
}

.width-3 {
  width: 3rem;
}

.height-30 .DraftEditor-editorContainer {
  overflow: hidden !important;
}

.paragraph-card {
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  overflow: hidden;
}

.home-card {
  min-height: 20rem;
}

.btn-status:disabled {
  opacity: 1;
}

.btn-status {
  transform: none !important;
}

.filter-black {
  position: absolute;

  width: 97%;
  height: 95%;
  background: rgba(0, 0, 0, 0.288);
  z-index: 99;
}

.public-chat-paragraph {
  position: absolute;
  top: 0;
  left: 4%;
  z-index: 99;
}

.h-4 {
  height: 3rem;
}

.quick-card {
  width: 100%;
  height: 12rem;
  border-radius: 1.375em;
}

.bg-quick-card {
  background-image: url("./assets/img/Background.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.animation-show {
  animation: opacity-show 1s ease-in;
}

.color-mygreen {
  color: #58d1c5;
}

.bg-mygreen {
  background-color: #58d1c5;
}

@keyframes opacity-show {
  0% {
    opacity: 0;
    display: none;
  }

  50% {
    display: flex;
  }

  100% {
    opacity: 1;
  }
}

.bg-chat {
  background: #f4f5f7;
}

.chat-message {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 240px;
  max-width: 90%;
  min-width: 85%;
}

.active-contact {
  background: var(--red);
  /*background: linear-gradient(87deg, #5e72e4 0, #825ee4 100%) !important;*/
  color: #fff !important;
}

.not-active-contact {
  background-color: #F4F5F7;
}

.max-content-height {
  max-height: max-content !important;
  height: max-content !important;
}

.gradient-chat {
  background: var(--red);
  background: linear-gradient(87deg, #5e72e4 0, #825ee4 100%) !important;
}

.chat-white {
  background: none;
  transition: all 0.5s;
}


.chat-blue {
  background: cyan;
  transition: all 0.5s;
}


.chat-red {
  background: orange;

  transition: all 0.5s;
}


.active-contact .chat-white,
.active-contact .chat-white:hover {
  color: #fff !important;
  font-size: 0.875rem;
}

.active-contact .chat-white.mt-1 {
  font-size: 0.75rem;
}

.contact-card {
  transition: all 0.5s;
}

.contact-card:hover {
  background: lightgrey;
}

.contact-card:hover .chat-white {
  color: #fff !important;
}

.contact-chat-height {
  height: calc(100vh - 5rem);
  max-height: 95vh;
}

.chat-online {
  position: absolute;
  top: 10%;
  right: 5%;
  background: #67cf8a;
  width: 1rem;
  height: 1rem;
  border-radius: 100%;
}

.chat-shadow {
  box-shadow:
    0 15px 35px rgba(50, 50, 93, 0.1),
    0 5px 15px rgba(0, 0, 0, 0.07);
}

.contact-message-height {
  height: calc(100vh - 20rem);
}

.chat-image {
  max-width: 10rem;
  object-fit: cover;
}

.contact-chat-height::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 0.5rem;
}

.contact-chat-height::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.contact-chat-height::-webkit-scrollbar-thumb {
  background-color: #825fe4;
  outline: 1px solid #825fe4;
  border-radius: 0.5rem;
}

.contact-message-height::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 0.5rem;
}

.contact-message-height::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.contact-message-height::-webkit-scrollbar-thumb {
  background-color: #825fe4;
  outline: 1px solid #825fe4;
  border-radius: 0.5rem;
}

.w-input {
  width: 500px;
  max-width: 90%;
}

.text-bold {
  font-weight: bold;
}

.not-seen {
  background-color: #e7e7e7;
}

.not-seen .chat-white {
  font-weight: 800;
}

.not-seen .chat-message {
  font-weight: 700;
}

@media screen and (max-width: 1200px) and (min-width: 900px) {
  .chat-header {
    flex-wrap: wrap;
  }

  .search-chat {
    margin-top: 1rem !important;
  }
}

.public-dashboard-detail-profile {
  margin: 4.5rem 0 !important;
  padding: 0.2rem 0.5rem !important;
}

.public-dashboard-jobboard {
  margin: 0 !important;
}

@media screen and (max-width: 800px) {
  .public-dashboard-detail-profile {
    margin: 5rem 0 !important;
    padding: 0.2rem 0.5rem !important;
  }

  .chat-header {
    flex-wrap: wrap;
  }

  .search-chat {
    margin-top: 1rem !important;
  }
}

.user-feedback-container {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.user-detail-container {
  display: flex;
  gap: 20px;
}

.user-detail {
  display: flex;
  flex-direction: column;
  align-self: center;
}

.rounded-img-feedback {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.card-payment-container {
  width: auto;
  padding: 2.5rem;
}

.title-header-payment {
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 600;
  line-height: 41px;
  letter-spacing: 0em;
  text-align: left;
}

.extra-payment-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
}

.extra-payment-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.payment-buttons-container {
  display: flex;
  gap: 25px;
}

.first-payment-button {
  background: #fa5252;
  width: 270px;
  height: 53px;
  border: 1px solid #c8c8c8;
  color: white;
  border-radius: 5px;
}

.second-payment-button {
  width: 270px;
  height: 53px;
  background: white;
  border: 1px solid #c8c8c8;
  border-radius: 5px;
}

.payment-container {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.user-type-payment {
  display: flex;
  gap: 2rem;
}

.prof-freelancer {
  display: flex;
  padding: 20px;
  padding-top: 0;
  flex-direction: column;
  border: 1px solid #172b4d;
  border-radius: 5px;
  align-items: center;
  gap: 1rem;
  max-width: 289px;
}

.price-title {
  font-family: "Public Sans";
  font-size: 36px !important;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.30000001192092896px;
  text-align: center;
  color: black;
  font-family: Public Sans;
}

.price-body {
  font-family: "Public Sans";
  font-size: 20px !important;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
  color: black;
  font-family: Public Sans;
}

.title-radio {
  font-family: "Public Sans";
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
  color: black;
  width: "100%";
}

.user-type-payment-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 600px;
}

.form-user-text {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.30000001192092896px;
  text-align: left;
}

.detail-payment {
  padding: 15px !important;
  font-family: "Public Sans";
  font-size: 15px !important;
  font-weight: "bold" !important;
  line-height: 24px !important;
  letter-spacing: -0.30000001192092896px !important;
  text-align: center;
  color: black;
}

.input-file-payment-container {
  border: 1px solid #5e72e4;
  width: 400px;
  display: flex;
  gap: 50px;
  align-items: center;
}

.button-payment-file {
  all: unset;
  padding: 10px;
  border-left: 1px solid #5e72e4;
}

.button-payment-file:hover {
  background-color: #5e72e4;
  color: white;
}

.file-label-payment {
  width: 100%;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  align-self: center;
  justify-self: center;
}

.radio-style-input {}

.radio-inputs {
  display: flex;
  gap: 15px;
}

.extra-payment-text-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.extra-payment-summary-text {
  color: black;
  font-family: " Open Sans";
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.payment-inner-container {
  display: flex;
  gap: 30px;
}

.membership-parent-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.membership-container {
  display: flex;
  gap: 20px;
  align-items: center;
  position: relative;
}

.membership-text {
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: black;
}

.membership-remove {
  all: unset;
  display: flex;
  align-items: center;
  gap: 5px;

  bottom: 0;
  right: 0;
}

.benefit-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.benefit-service {
  display: flex;
  gap: 20px;
  align-items: center;
}

.green-icons {
  color: rgb(11, 220, 11);
}

.add-contact-width {
  min-width: 80%;
  min-height: auto;
  position: relative;
}

.footer-contact-modal {
  display: flex;
  justify-content: flex-end;
}

.footer-contact-modal>.btn-submit-contact {
  background-color: #4f4f4f;
  border-color: #4f4f4f;
  color: #fff;
}

.footer-contact-modal>.btn-close-contact {
  background-color: #fff;
  border-color: #4f4f4f;
  color: #4f4f4f;
}

.add-contact-button-width {
  width: auto;
  text-align: center;
  min-width: 330px;
}

.add-contact-first-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.add-contact-container {
  width: 100%;
}

.inner-add-contact-container {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 50px;
}

.private-notes-text-field {
  height: 80px !important;
}

.save-new-contact {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.important {
  position: absolute;
  left: 1%;
  width: 6rem;
  overflow: hidden;
  color: red;
  top: 50%;
  transform: translateY(-50%);
}

.important-container {
  flex: 1;
  position: relative;
}

.pad-6 {
  padding-left: 7rem !important;
}

.width-45 {
  width: 45% !important;
}

.width-50 {
  width: 50% !important;
}

.width-250 {
  width: 255px !important;
}

@media screen and (max-width: 979px) {
  .width-45 {
    width: 100% !important;
  }

  .width-50 {
    width: 100% !important;
  }

  .width-250 {
    width: 100% !important;
  }
}